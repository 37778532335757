@import url(https://fonts.googleapis.com/css?family=Roboto:400,  100,300,500,700);
@import url(https://fonts.googleapis.com/css?family=Open+Sans);
@import url(https://fonts.googleapis.com/css?family=Galada);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: "tnum", "tnum", "tnum";
}

/*sidebar */

.ant-tabs-bar-sidebar {
  margin: 0 0 16px 0;
  border-bottom: 1px solid rgb(0, 37, 58) !important;
  outline: none;
  transition: padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

/*End Sidebar*/

/* Media query */
@media (min-width: 1200px) and (max-width: 1600px) {
  .col-lg-6 {
    flex: 0 0 50% !important;
    max-width: 50% !important;
  }
  .col-lg-3 {
    flex: 0 0 50% !important;
    max-width: 50% !important;
  }
  .spaceBw1200-1600 {
    width: 100%;
  }
  .btnRaport {
    width: 100%;
    float: right;
    margin-top: 10px;
  }
}

@media (min-width: 992px) and (max-width: 1300px) {
  .col-md-2 {
    flex: 0 0 50% !important;
    max-width: 50% !important;
  }
  .col-md-4 {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
  .btnRaport {
    width: 100%;
    float: right;
    margin-top: 10px;
  }
}

/*End Media query */
.logo {
  height: 54px;
  margin: 0px;
  margin-left: 35px;
  margin-top: 13px;
}

/* Energy use, week to date dropdown menu */
.e-checkbox-wrapper .e-frame.e-check,
.e-css.e-checkbox-wrapper .e-frame.e-check {
  background-color: cadetblue !important;
  border-color: transparent;
  color: #fff;
}
.e-input-group:not(.e-float-icon-left):not(.e-float-input)::before,
.e-input-group:not(.e-float-icon-left):not(.e-float-input)::after,
.e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::before,
.e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::after,
.e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::before,
.e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::after,
.e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input)
  .e-input-in-wrap::before,
.e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input)
  .e-input-in-wrap::after {
  background: cadetblue !important;
}
.e-dropdownbase .e-list-item.e-active,
.e-dropdownbase .e-list-item.e-active.e-hover {
  background-color: #eee;
  border-color: #fff;
  color: cadetblue !important;
}

/*END Energy use, week to date dropdown menu */

/* ant-menu-submenu-arrow */
.ant-menu-submenu-inline
  > .ant-menu-submenu-title
  .ant-menu-submenu-arrow::after {
  transform: rotate(45deg) translateY(-2px);
}

.ant-menu-submenu-inline
  > .ant-menu-submenu-title
  .ant-menu-submenu-arrow::before {
  transform: rotate(-45deg) translateY(2px);
}

/*END ant-menu-submenu-arrow */

/* On hover logout content*/

.ant-btn-primary {
  color: #fff;
  background-color: #5f9ea0;
  border-color: #5f9ea0;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}

.ant-btn-primary:hover,
.ant-btn-primary:focus {
  color: #fff;
  background-color: #2a7d80;
  border-color: #5f9ea0;
}

.anticon {
  display: inline-block;
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: 0em;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/*END On hover logout content*/

.ant-layout-sider-has-trigger {
  padding-bottom: 48px;
}
¨ .ant-layout-sider {
  position: relative;
  min-width: 0;
  background: #001529;
  transition: all 0.2s;
}
.ant-layout {
  display: flex;
  flex: auto;
  flex-direction: column;
  min-height: 0;
  background: rgb(252, 252, 253) !important;
}
.ant-layout-footer {
  padding: 24px 50px;
  color: rgba(136, 115, 115, 0.65);
  font-size: 14px;
  background: rgb(252, 252, 253);
}
.ant-layout.ant-layout-has-sider > .ant-layout {
  overflow-x: hidden;
}

.ant-layout-overflow .ant-layout {
  overflow: auto;
}

.ant-layout {
  display: flex;
  flex: auto;
  flex-direction: column;
  min-height: 0;
  background: #f0f2f5;
}

.ant-layout-overflow .main-content {
  position: relative;
}

.ant-layout-content {
  flex: auto;
}

.content-paddings {
  padding: 30px 30px;
}

.ant-layout.ant-layout-has-sider {
  flex-direction: row;
}

.ant-layout-overflow {
  height: 100vh;
  display: flex;
  flex-direction: row;
}
.kCpHmc {
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 330;
  pointer-events: none;
  opacity: 0.1;
  transition: all 0.5s ease 0s;
}

.ant-layout-sider-trigger {
  position: relative !important;
  bottom: 0 !important;
  z-index: 1 !important;
  height: 48px !important;
  color: #fff !important;
  line-height: 48px !important;
  text-align: center !important;
  background: #002140 !important;
  cursor: pointer !important;
  transition: all 0.2s !important;
}

.logo-small {
  height: 40px;
  margin: 0px;
  margin-left: 14px;
  margin-top: 13px;
}
.e-tab .e-tab-header .e-toolbar-item.e-active .e-tab-text,
.e-tab .e-tab-header .e-toolbar-item.e-active .e-tab-icon {
  color: white !important;
}

.e-tab .e-tab-header .e-indicator {
  background: cadetblue !important;
}

.e-tab .e-tab-header .e-toolbar-item .e-tab-wrap {
  border-radius: 0;
  color: white !important;
}

.ant-layout-header {
  height: 50px;
  padding: 0 50px;
  line-height: 64px;
  background: #001529;
}

.ant-layout-sider {
  position: relative;
  min-width: 0;
  background: rgb(0, 37, 58);
  transition: all 0.2s;
}

.ant-menu-dark,
.ant-menu-dark .ant-menu-sub {
  color: rgba(255, 255, 255, 0.65);
  background: rgb(0, 37, 58);
}

.so-tabs-header-tabs {
  display: flex;
  flex: 1 1;
  color: white;
  overflow: hidden;
}

.so-tabs-header .so-tabs-hr {
  position: absolute;
  z-index: 10;
  bottom: 0;
  width: 0%;
  border-bottom: 0px solid transparent !important;
}

.so-tabs-line .so-tabs-active {
  /* color: #39f; */
  color: white !important;
}

.so-tabs-line .so-tabs-active:after {
  position: absolute;
  width: 100%;
  height: 2px;
  background: cadetblue !important;
  content: " ";
}

.so-tabs-inner {
  overflow: unset !important;
  flex: 0 1 !important;
}

.so-tabs-panel.so-tabs-show {
  background-color: rgb(0, 37, 58) !important;
}
.so-tabs-scroll {
  display: inline-block;
  transition: margin 0.2s linear;
  white-space: nowrap;
  margin-left: 20px !important;
}

.ant-menu.ant-menu-dark .ant-menu-item-selected,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
  background-color: #5f9ea0;
  border-bottom: 2px solid #00253a;
}
.ant-menu-item:active,
.ant-menu-submenu-title:active {
  background: #00253a !important;
}

.ant-tree {
  box-sizing: border-box;
  color: #94631a;
  font-size: 13px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: "tnum", "tnum", "tnum";
  margin: 0px 0px 10px 1px;
  padding: 0px;
}
.jxaNuJ span {
  color: rgb(184, 199, 206) !important;
}
.ant-tree li .ant-tree-node-content-wrapper.ant-tree-node-selected {
  background-color: #5f9ea0;
}
.ant-tree li .ant-tree-node-content-wrapper:hover {
  background-color: #5f9ea0;
}

/* Login page */
/*Fonts*/

::selection {
  background: #ffb7b7; /* WebKit/Blink Browsers */
}
::-moz-selection {
  background: #ffb7b7; /* Gecko Browsers */
}
* {
  box-sizing: border-box;
}
:focus {
  outline: none;
}
/*Reset*/

form {
  margin: 10px 35px;
}
input {
  border: none;
}
a {
  text-decoration: none;
  color: rgb(255, 255, 255);
}
a:hover {
  color: rgba(255, 152, 0, 0.79);
  text-decoration: underline;
}

#icon {
  background-color: #f4f4f4;
  color: #625864;
  display: inline-block;
  font-size: 14px;
  padding-top: 10px;
  padding-bottom: 7px;
  width: 40px;
  margin-left: 15px;
  margin-bottom: 20px;
  text-align: center;
  border-top: solid 1px #cbc9c9;
  border-bottom: solid 1px #cbc9c9;
  border-left: solid 1px #cbc9c9;
  border-radius: 3px 0 0 3px;
  -webkit-border-radius: 3px 0 0 3px;
  -moz-border-radius: 3px 0 0 3px;
}
.wrapper {
  margin: 50px auto;
  width: 343px;
  height: 280px;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
}
.wrapper h1 {
  font-family: "Galada", cursive;
  color: #f4f4f4;
  letter-spacing: 8px;
  text-align: center;
  padding-top: 5px;
  padding-bottom: 5px;
}
.wrapper hr {
  opacity: 0.2;
}
.crtacc {
  margin-left: 75px;
}

/* Username, image and logout button on Navbar */

.navbar-custom-menu {
  float: right;
  margin-right: 20px;
}
@media (min-width: 768px) {
  .navbar-nav {
    float: left;
    margin: 0;
  }
  .nav {
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
  }
  .navbar-nav > li {
    float: right;
  }
  .navbar-nav > li > a {
    padding-top: 15px;
    padding-bottom: 15px;
  }
}

.navbar-custom-menu > .navbar-nav > li {
  position: relative;
}
.navbar-nav > li {
  float: left;
}
.nav > li {
  position: relative;
  display: block;
}

.navbar .nav > li > a {
  color: #fff;
}

.navbar-nav > li > a {
  padding-top: 10px;
  padding-bottom: 10px;
  line-height: 20px;
}

.nav > li > a {
  position: relative;
  display: block;
  padding: 10px 15px;
}

.navbar-nav > .user-menu .user-image {
  float: left;
  width: 25px;
  height: 25px;
  margin-top: 12px;
  border-radius: 50%;
  margin-right: 10px;
  background-color: white;
}

.nav > li > a > img {
  max-width: none;
}

.navbar .nav > li > a {
  color: #fff;
}
.navbar .nav > li > a:hover {
  text-decoration: none;
}
.navbar .nav > li > a:hover {
  text-decoration: none;
}
.user-initial {
  font-size: 12px;
  text-align: center;
  margin-top: -12px;
  color: cadetblue;
  font-weight: 600;
}
a:active,
a:hover {
  text-decoration: none !important;
  outline: 0 !important;
}
.navbar-custom-menu > .navbar-nav > li > .dropdown-menu {
  position: absolute;
  right: 0;
  left: auto;
}
.username {
  text-decoration: none;
  color: white;
  font-size: 14px;
}

/* Main Content CSS */

.content-wrapper {
  padding: 32px 48px;
}
.display-timeseries-details {
  display: inline-block;
  font-size: 14px;
  width: 100%;
  padding: 8px;
  border-bottom: 1px solid rgb(217, 217, 217);
}

/* Progress bar */
.progress-circle {
  font-size: 20px;
  margin: 20px;
  position: relative; /* so that children can be absolutely positioned */
  padding: 0;
  width: 5em;
  height: 5em;
  background-color: #f2e9e1;
  border-radius: 50%;
  line-height: 5em;
  float: left;
}
.assetTree-size {
  width: 255px;
  max-height: 72vh;
  overflow: auto;
}

.progress-circle:after {
  border: none;
  position: absolute;
  top: 0.35em;
  left: 0.35em;
  text-align: center;
  display: block;
  border-radius: 50%;
  width: 4.3em;
  height: 4.3em;
  background-color: white;
  content: " ";
}
/* Text inside the control */
.progress-circle span {
  position: absolute;
  line-height: 5em;
  width: 5em;
  text-align: center;
  display: block;
  color: #53777a;
  z-index: 2;
}
.left-half-clipper {
  /* a round circle */
  border-radius: 50%;
  width: 5em;
  height: 5em;
  position: absolute; /* needed for clipping */
  clip: rect(0, 5em, 5em, 2.5em); /* clips the whole left half*/
}
/* when p>50, don't clip left half*/
.progress-circle.over50 .left-half-clipper {
  clip: rect(auto, auto, auto, auto);
}
.value-bar {
  /*This is an overlayed square, that is made round with the border radius,
  then it is cut to display only the left half, then rotated clockwise
  to escape the outer clipping path.*/
  position: absolute; /*needed for clipping*/
  clip: rect(0, 2.5em, 5em, 0);
  width: 5em;
  height: 5em;
  border-radius: 50%;
  border: 0.45em solid #53777a; /*The border is 0.35 but making it larger removes visual artifacts */
  /*background-color: #4D642D;*/ /* for debug */
  box-sizing: border-box;
}
/* Progress bar filling the whole right half for values above 50% */
.progress-circle.over50 .first50-bar {
  /*Progress bar for the first 50%, filling the whole right half*/
  position: absolute; /*needed for clipping*/
  clip: rect(0, 5em, 5em, 2.5em);
  background-color: #53777a;
  border-radius: 50%;
  width: 5em;
  height: 5em;
}
.progress-circle:not(.over50) .first50-bar {
  display: none;
}

/* Progress bar rotation position */
.progress-circle.p0 .value-bar {
  display: none;
}
.progress-circle.p1 .value-bar {
  transform: rotate(4deg);
}
.progress-circle.p2 .value-bar {
  transform: rotate(7deg);
}
.progress-circle.p3 .value-bar {
  transform: rotate(11deg);
}
.progress-circle.p4 .value-bar {
  transform: rotate(14deg);
}
.progress-circle.p5 .value-bar {
  transform: rotate(18deg);
}
.progress-circle.p6 .value-bar {
  transform: rotate(22deg);
}
.progress-circle.p7 .value-bar {
  transform: rotate(25deg);
}
.progress-circle.p8 .value-bar {
  transform: rotate(29deg);
}
.progress-circle.p9 .value-bar {
  transform: rotate(32deg);
}
.progress-circle.p10 .value-bar {
  transform: rotate(36deg);
}
.progress-circle.p11 .value-bar {
  transform: rotate(40deg);
}
.progress-circle.p12 .value-bar {
  transform: rotate(43deg);
}
.progress-circle.p13 .value-bar {
  transform: rotate(47deg);
}
.progress-circle.p14 .value-bar {
  transform: rotate(50deg);
}
.progress-circle.p15 .value-bar {
  transform: rotate(54deg);
}
.progress-circle.p16 .value-bar {
  transform: rotate(58deg);
}
.progress-circle.p17 .value-bar {
  transform: rotate(61deg);
}
.progress-circle.p18 .value-bar {
  transform: rotate(65deg);
}
.progress-circle.p19 .value-bar {
  transform: rotate(68deg);
}
.progress-circle.p20 .value-bar {
  transform: rotate(72deg);
}
.progress-circle.p21 .value-bar {
  transform: rotate(76deg);
}
.progress-circle.p22 .value-bar {
  transform: rotate(79deg);
}
.progress-circle.p23 .value-bar {
  transform: rotate(83deg);
}
.progress-circle.p24 .value-bar {
  transform: rotate(86deg);
}
.progress-circle.p25 .value-bar {
  transform: rotate(90deg);
}
.progress-circle.p26 .value-bar {
  transform: rotate(94deg);
}
.progress-circle.p27 .value-bar {
  transform: rotate(97deg);
}
.progress-circle.p28 .value-bar {
  transform: rotate(101deg);
}
.progress-circle.p29 .value-bar {
  transform: rotate(104deg);
}
.progress-circle.p30 .value-bar {
  transform: rotate(108deg);
}
.progress-circle.p31 .value-bar {
  transform: rotate(112deg);
}
.progress-circle.p32 .value-bar {
  transform: rotate(115deg);
}
.progress-circle.p33 .value-bar {
  transform: rotate(119deg);
}
.progress-circle.p34 .value-bar {
  transform: rotate(122deg);
}
.progress-circle.p35 .value-bar {
  transform: rotate(126deg);
}
.progress-circle.p36 .value-bar {
  transform: rotate(130deg);
}
.progress-circle.p37 .value-bar {
  transform: rotate(133deg);
}
.progress-circle.p38 .value-bar {
  transform: rotate(137deg);
}
.progress-circle.p39 .value-bar {
  transform: rotate(140deg);
}
.progress-circle.p40 .value-bar {
  transform: rotate(144deg);
}
.progress-circle.p41 .value-bar {
  transform: rotate(148deg);
}
.progress-circle.p42 .value-bar {
  transform: rotate(151deg);
}
.progress-circle.p43 .value-bar {
  transform: rotate(155deg);
}
.progress-circle.p44 .value-bar {
  transform: rotate(158deg);
}
.progress-circle.p45 .value-bar {
  transform: rotate(162deg);
}
.progress-circle.p46 .value-bar {
  transform: rotate(166deg);
}
.progress-circle.p47 .value-bar {
  transform: rotate(169deg);
}
.progress-circle.p48 .value-bar {
  transform: rotate(173deg);
}
.progress-circle.p49 .value-bar {
  transform: rotate(176deg);
}
.progress-circle.p50 .value-bar {
  transform: rotate(180deg);
}
.progress-circle.p51 .value-bar {
  transform: rotate(184deg);
}
.progress-circle.p52 .value-bar {
  transform: rotate(187deg);
}
.progress-circle.p53 .value-bar {
  transform: rotate(191deg);
}
.progress-circle.p54 .value-bar {
  transform: rotate(194deg);
}
.progress-circle.p55 .value-bar {
  transform: rotate(198deg);
}
.progress-circle.p56 .value-bar {
  transform: rotate(202deg);
}
.progress-circle.p57 .value-bar {
  transform: rotate(205deg);
}
.progress-circle.p58 .value-bar {
  transform: rotate(209deg);
}
.progress-circle.p59 .value-bar {
  transform: rotate(212deg);
}
.progress-circle.p60 .value-bar {
  transform: rotate(216deg);
}
.progress-circle.p61 .value-bar {
  transform: rotate(220deg);
}
.progress-circle.p62 .value-bar {
  transform: rotate(223deg);
}
.progress-circle.p63 .value-bar {
  transform: rotate(227deg);
}
.progress-circle.p64 .value-bar {
  transform: rotate(230deg);
}
.progress-circle.p65 .value-bar {
  transform: rotate(234deg);
}
.progress-circle.p66 .value-bar {
  transform: rotate(238deg);
}
.progress-circle.p67 .value-bar {
  transform: rotate(241deg);
}
.progress-circle.p68 .value-bar {
  transform: rotate(245deg);
}
.progress-circle.p69 .value-bar {
  transform: rotate(248deg);
}
.progress-circle.p70 .value-bar {
  transform: rotate(252deg);
}
.progress-circle.p71 .value-bar {
  transform: rotate(256deg);
}
.progress-circle.p72 .value-bar {
  transform: rotate(259deg);
}
.progress-circle.p73 .value-bar {
  transform: rotate(263deg);
}
.progress-circle.p74 .value-bar {
  transform: rotate(266deg);
}
.progress-circle.p75 .value-bar {
  transform: rotate(270deg);
}
.progress-circle.p76 .value-bar {
  transform: rotate(274deg);
}
.progress-circle.p77 .value-bar {
  transform: rotate(277deg);
}
.progress-circle.p78 .value-bar {
  transform: rotate(281deg);
}
.progress-circle.p79 .value-bar {
  transform: rotate(284deg);
}
.progress-circle.p80 .value-bar {
  transform: rotate(288deg);
}
.progress-circle.p81 .value-bar {
  transform: rotate(292deg);
}
.progress-circle.p82 .value-bar {
  transform: rotate(295deg);
}
.progress-circle.p83 .value-bar {
  transform: rotate(299deg);
}
.progress-circle.p84 .value-bar {
  transform: rotate(302deg);
}
.progress-circle.p85 .value-bar {
  transform: rotate(306deg);
}
.progress-circle.p86 .value-bar {
  transform: rotate(310deg);
}
.progress-circle.p87 .value-bar {
  transform: rotate(313deg);
}
.progress-circle.p88 .value-bar {
  transform: rotate(317deg);
}
.progress-circle.p89 .value-bar {
  transform: rotate(320deg);
}
.progress-circle.p90 .value-bar {
  transform: rotate(324deg);
}
.progress-circle.p91 .value-bar {
  transform: rotate(328deg);
}
.progress-circle.p92 .value-bar {
  transform: rotate(331deg);
}
.progress-circle.p93 .value-bar {
  transform: rotate(335deg);
}
.progress-circle.p94 .value-bar {
  transform: rotate(338deg);
}
.progress-circle.p95 .value-bar {
  transform: rotate(342deg);
}
.progress-circle.p96 .value-bar {
  transform: rotate(346deg);
}
.progress-circle.p97 .value-bar {
  transform: rotate(349deg);
}
.progress-circle.p98 .value-bar {
  transform: rotate(353deg);
}
.progress-circle.p99 .value-bar {
  transform: rotate(356deg);
}
.progress-circle.p100 .value-bar {
  transform: rotate(360deg);
}

/*////////////////////////////////////////////////////////////*/

.hidden {
  visibility: hidden;
  width: 0;
}

.progress-bar {
  background: #f1f6fa;
  border-radius: 5px;
  box-shadow: inset 0 0 0 1px #ccd6dd;
  height: 10px;
  overflow: hidden;
  position: relative;
  text-indent: 100%;
  width: 300px;
}
.progress-bar--counter {
  margin-left: 10px;
  position: relative;
  top: -1px;
}
.progress-bar--counter .hidden {
  display: inline-block;
}
.progress-bar--wrap {
  display: flex;
  display: -moz-flex;
  display: flex;
  font-size: 13px;
  font-weight: 500;
  line-height: 1;
  margin: 10px 0;
}
.progress-bar--inner {
  transition: all 0.5s ease-in-out;
  border-radius: 10px;
  height: 10px;
  left: 0;
  min-height: 10px;
  position: absolute;
  top: 0;
}

.progress-bar--green {
  color: #6fc14b;
}
.progress-bar--green .progress-bar--inner {
  background-color: #6fc14b;
}
.progress-bar--blue {
  color: #068eda;
}
.progress-bar--blue .progress-bar--inner {
  background-color: #068eda;
}
.progress-bar--red {
  color: #fb797e;
}
.progress-bar--red .progress-bar--inner {
  background-color: #fb797e;
}
.progress-bar--yellow {
  color: #f7a81d;
}
.progress-bar--yellow .progress-bar--inner {
  background-color: #f7a81d;
}

/*////////////////////////////////////////////////////////////*/

.bars {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.bars > div {
  margin: 10px;
}
.bars > div:nth-child(odd) {
  margin-left: 0;
}

.progress-bar2 {
  background: #dee2e3;
  border-radius: 99px;
  width: 200px;
  height: 5px;
  position: relative;
  overflow: hidden;
}
.progress-bar2::before {
  border-radius: 99px;
  position: absolute;
  height: 5px;
  background: #1e9ef6;
  content: "";
  width: 0;
  transition: width 0.2s;
}

.progress-bar-10::before {
  width: 10%;
}

.progress-bar-20::before {
  width: 20%;
}

.progress-bar-30::before {
  width: 30%;
}

.progress-bar-40::before {
  width: 40%;
}

.progress-bar-50::before {
  width: 50%;
}

.progress-bar-60::before {
  width: 60%;
}

.progress-bar-70::before {
  width: 70%;
}

.progress-bar-80::before {
  width: 80%;
}

.progress-bar-90::before {
  width: 90%;
}

.progress-bar-100::before {
  width: 100%;
}

/****************************************************************
*
*
*****************************************************************/

.progressWrapper {
  position: relative;
  width: 250px;
}
.progressWrapper .progressColor {
  width: 250px;
  height: 12px;
  margin-bottom: 60px;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  border: none;
  border-radius: 2px;
  overflow: hidden;
  background-color: #d7d7d7;
}
.progressWrapper .progressColor::-webkit-progress-bar {
  background-color: #d7d7d7;
  -webkit-transition: 4s;
  transition: 4s;
}
.progressWrapper .tooltip2 {
  display: inline-block;
  background-color: #717880;
  font-size: 11px;
  color: #fff;
  padding: 4px 8px;
  width: auto;
  border-radius: 3px;
  position: absolute;
  top: -27px;
  left: 0%;
  margin-left: -15px;
}

.progressWrapper .tooltip2:after {
  content: "";
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  border-top: 3px solid #717880;
  position: absolute;
  left: 50%;
  top: 100%;
  transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
}

.progressWrapper .tooltip2 {
  transition: 0.4s;
  left: 0%;
}

.progressWrapper progress.progressColor::-moz-progress-bar {
  background: #08da9d;
  border-radius: 2px;
  position: relative;
}

.progressWrapper progress.progressColor::-webkit-progress-value {
  background: #08da9d;
  border-radius: 2px;
  position: relative;
}
.progressColor progress[value] {
  color: #08da9d;
}
.progressColor progress[value]::-webkit-progress-bar {
  background-color: #d7d7d7;
}

/****************************************************************
*
* CSS Percentage Circle
* Author: Andre Firchow
*
*****************************************************************/

.rect-auto,
.c100.p51 .slice,
.c100.p52 .slice,
.c100.p53 .slice,
.c100.p54 .slice,
.c100.p55 .slice,
.c100.p56 .slice,
.c100.p57 .slice,
.c100.p58 .slice,
.c100.p59 .slice,
.c100.p60 .slice,
.c100.p61 .slice,
.c100.p62 .slice,
.c100.p63 .slice,
.c100.p64 .slice,
.c100.p65 .slice,
.c100.p66 .slice,
.c100.p67 .slice,
.c100.p68 .slice,
.c100.p69 .slice,
.c100.p70 .slice,
.c100.p71 .slice,
.c100.p72 .slice,
.c100.p73 .slice,
.c100.p74 .slice,
.c100.p75 .slice,
.c100.p76 .slice,
.c100.p77 .slice,
.c100.p78 .slice,
.c100.p79 .slice,
.c100.p80 .slice,
.c100.p81 .slice,
.c100.p82 .slice,
.c100.p83 .slice,
.c100.p84 .slice,
.c100.p85 .slice,
.c100.p86 .slice,
.c100.p87 .slice,
.c100.p88 .slice,
.c100.p89 .slice,
.c100.p90 .slice,
.c100.p91 .slice,
.c100.p92 .slice,
.c100.p93 .slice,
.c100.p94 .slice,
.c100.p95 .slice,
.c100.p96 .slice,
.c100.p97 .slice,
.c100.p98 .slice,
.c100.p99 .slice,
.c100.p100 .slice {
  clip: rect(auto, auto, auto, auto);
}

.pie,
.c100 .bar,
.c100.p51 .fill,
.c100.p52 .fill,
.c100.p53 .fill,
.c100.p54 .fill,
.c100.p55 .fill,
.c100.p56 .fill,
.c100.p57 .fill,
.c100.p58 .fill,
.c100.p59 .fill,
.c100.p60 .fill,
.c100.p61 .fill,
.c100.p62 .fill,
.c100.p63 .fill,
.c100.p64 .fill,
.c100.p65 .fill,
.c100.p66 .fill,
.c100.p67 .fill,
.c100.p68 .fill,
.c100.p69 .fill,
.c100.p70 .fill,
.c100.p71 .fill,
.c100.p72 .fill,
.c100.p73 .fill,
.c100.p74 .fill,
.c100.p75 .fill,
.c100.p76 .fill,
.c100.p77 .fill,
.c100.p78 .fill,
.c100.p79 .fill,
.c100.p80 .fill,
.c100.p81 .fill,
.c100.p82 .fill,
.c100.p83 .fill,
.c100.p84 .fill,
.c100.p85 .fill,
.c100.p86 .fill,
.c100.p87 .fill,
.c100.p88 .fill,
.c100.p89 .fill,
.c100.p90 .fill,
.c100.p91 .fill,
.c100.p92 .fill,
.c100.p93 .fill,
.c100.p94 .fill,
.c100.p95 .fill,
.c100.p96 .fill,
.c100.p97 .fill,
.c100.p98 .fill,
.c100.p99 .fill,
.c100.p100 .fill {
  position: absolute;
  border: 0.08em solid #307bbb;
  width: 0.84em;
  height: 0.84em;
  clip: rect(0em, 0.5em, 1em, 0em);
  border-radius: 50%;
  transform: rotate(0deg);
}

.pie-fill,
.c100.p51 .bar:after,
.c100.p51 .fill,
.c100.p52 .bar:after,
.c100.p52 .fill,
.c100.p53 .bar:after,
.c100.p53 .fill,
.c100.p54 .bar:after,
.c100.p54 .fill,
.c100.p55 .bar:after,
.c100.p55 .fill,
.c100.p56 .bar:after,
.c100.p56 .fill,
.c100.p57 .bar:after,
.c100.p57 .fill,
.c100.p58 .bar:after,
.c100.p58 .fill,
.c100.p59 .bar:after,
.c100.p59 .fill,
.c100.p60 .bar:after,
.c100.p60 .fill,
.c100.p61 .bar:after,
.c100.p61 .fill,
.c100.p62 .bar:after,
.c100.p62 .fill,
.c100.p63 .bar:after,
.c100.p63 .fill,
.c100.p64 .bar:after,
.c100.p64 .fill,
.c100.p65 .bar:after,
.c100.p65 .fill,
.c100.p66 .bar:after,
.c100.p66 .fill,
.c100.p67 .bar:after,
.c100.p67 .fill,
.c100.p68 .bar:after,
.c100.p68 .fill,
.c100.p69 .bar:after,
.c100.p69 .fill,
.c100.p70 .bar:after,
.c100.p70 .fill,
.c100.p71 .bar:after,
.c100.p71 .fill,
.c100.p72 .bar:after,
.c100.p72 .fill,
.c100.p73 .bar:after,
.c100.p73 .fill,
.c100.p74 .bar:after,
.c100.p74 .fill,
.c100.p75 .bar:after,
.c100.p75 .fill,
.c100.p76 .bar:after,
.c100.p76 .fill,
.c100.p77 .bar:after,
.c100.p77 .fill,
.c100.p78 .bar:after,
.c100.p78 .fill,
.c100.p79 .bar:after,
.c100.p79 .fill,
.c100.p80 .bar:after,
.c100.p80 .fill,
.c100.p81 .bar:after,
.c100.p81 .fill,
.c100.p82 .bar:after,
.c100.p82 .fill,
.c100.p83 .bar:after,
.c100.p83 .fill,
.c100.p84 .bar:after,
.c100.p84 .fill,
.c100.p85 .bar:after,
.c100.p85 .fill,
.c100.p86 .bar:after,
.c100.p86 .fill,
.c100.p87 .bar:after,
.c100.p87 .fill,
.c100.p88 .bar:after,
.c100.p88 .fill,
.c100.p89 .bar:after,
.c100.p89 .fill,
.c100.p90 .bar:after,
.c100.p90 .fill,
.c100.p91 .bar:after,
.c100.p91 .fill,
.c100.p92 .bar:after,
.c100.p92 .fill,
.c100.p93 .bar:after,
.c100.p93 .fill,
.c100.p94 .bar:after,
.c100.p94 .fill,
.c100.p95 .bar:after,
.c100.p95 .fill,
.c100.p96 .bar:after,
.c100.p96 .fill,
.c100.p97 .bar:after,
.c100.p97 .fill,
.c100.p98 .bar:after,
.c100.p98 .fill,
.c100.p99 .bar:after,
.c100.p99 .fill,
.c100.p100 .bar:after,
.c100.p100 .fill {
  transform: rotate(180deg);
}

.c100 {
  position: relative;
  font-size: 120px;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  float: left;
  margin: 0 0.1em 0.1em 0;
  background-color: #cccccc;
}

.c100 *,
.c100 *:before,
.c100 *:after {
  box-sizing: content-box;
}

.c100.center {
  float: none;
  margin: 0 auto;
}

.c100.big {
  font-size: 240px;
}

.c100.small {
  font-size: 80px;
}

.c100 > span {
  position: absolute;
  width: 100%;
  z-index: 1;
  left: 0;
  top: 0;
  width: 5em;
  line-height: 5em;
  font-size: 0.2em;
  color: #cccccc;
  display: block;
  text-align: center;
  white-space: nowrap;
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
}

.c100:after {
  position: absolute;
  top: 0.08em;
  left: 0.08em;
  display: block;
  content: " ";
  border-radius: 50%;
  background-color: whitesmoke;
  width: 0.84em;
  height: 0.84em;
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: ease-in;
}

.c100 .slice {
  position: absolute;
  width: 1em;
  height: 1em;
  clip: rect(0em, 1em, 1em, 0.5em);
}

.c100.p1 .bar {
  transform: rotate(3.6deg);
}

.c100.p2 .bar {
  transform: rotate(7.2deg);
}

.c100.p3 .bar {
  transform: rotate(10.8deg);
}

.c100.p4 .bar {
  transform: rotate(14.4deg);
}

.c100.p5 .bar {
  transform: rotate(18deg);
}

.c100.p6 .bar {
  transform: rotate(21.6deg);
}

.c100.p7 .bar {
  transform: rotate(25.2deg);
}

.c100.p8 .bar {
  transform: rotate(28.8deg);
}

.c100.p9 .bar {
  transform: rotate(32.4deg);
}

.c100.p10 .bar {
  transform: rotate(36deg);
}

.c100.p11 .bar {
  transform: rotate(39.6deg);
}

.c100.p12 .bar {
  transform: rotate(43.2deg);
}

.c100.p13 .bar {
  transform: rotate(46.8deg);
}

.c100.p14 .bar {
  transform: rotate(50.4deg);
}

.c100.p15 .bar {
  transform: rotate(54deg);
}

.c100.p16 .bar {
  transform: rotate(57.6deg);
}

.c100.p17 .bar {
  transform: rotate(61.2deg);
}

.c100.p18 .bar {
  transform: rotate(64.8deg);
}

.c100.p19 .bar {
  transform: rotate(68.4deg);
}

.c100.p20 .bar {
  transform: rotate(72deg);
}

.c100.p21 .bar {
  transform: rotate(75.6deg);
}

.c100.p22 .bar {
  transform: rotate(79.2deg);
}

.c100.p23 .bar {
  transform: rotate(82.8deg);
}

.c100.p24 .bar {
  transform: rotate(86.4deg);
}

.c100.p25 .bar {
  transform: rotate(90deg);
}

.c100.p26 .bar {
  transform: rotate(93.6deg);
}

.c100.p27 .bar {
  transform: rotate(97.2deg);
}

.c100.p28 .bar {
  transform: rotate(100.8deg);
}

.c100.p29 .bar {
  transform: rotate(104.4deg);
}

.c100.p30 .bar {
  transform: rotate(108deg);
}

.c100.p31 .bar {
  transform: rotate(111.6deg);
}

.c100.p32 .bar {
  transform: rotate(115.2deg);
}

.c100.p33 .bar {
  transform: rotate(118.8deg);
}

.c100.p34 .bar {
  transform: rotate(122.4deg);
}

.c100.p35 .bar {
  transform: rotate(126deg);
}

.c100.p36 .bar {
  transform: rotate(129.6deg);
}

.c100.p37 .bar {
  transform: rotate(133.2deg);
}

.c100.p38 .bar {
  transform: rotate(136.8deg);
}

.c100.p39 .bar {
  transform: rotate(140.4deg);
}

.c100.p40 .bar {
  transform: rotate(144deg);
}

.c100.p41 .bar {
  transform: rotate(147.6deg);
}

.c100.p42 .bar {
  transform: rotate(151.2deg);
}

.c100.p43 .bar {
  transform: rotate(154.8deg);
}

.c100.p44 .bar {
  transform: rotate(158.4deg);
}

.c100.p45 .bar {
  transform: rotate(162deg);
}

.c100.p46 .bar {
  transform: rotate(165.6deg);
}

.c100.p47 .bar {
  transform: rotate(169.2deg);
}

.c100.p48 {
  transform: rotate(172.8deg);
}

.c100.p49 .bar {
  transform: rotate(176.4deg);
}

.c100.p50 .bar {
  transform: rotate(180deg);
}

.c100.p51 .bar {
  transform: rotate(183.6deg);
}

.c100.p52 .bar {
  transform: rotate(187.2deg);
}

.c100.p53 .bar {
  transform: rotate(190.8deg);
}

.c100.p54 .bar {
  transform: rotate(194.4deg);
}

.c100.p55 .bar {
  transform: rotate(198deg);
}

.c100.p56 .bar {
  transform: rotate(201.6deg);
}

.c100.p57 .bar {
  transform: rotate(205.2deg);
}

.c100.p58 .bar {
  transform: rotate(208.8deg);
}

.c100.p59 .bar {
  transform: rotate(212.4deg);
}

.c100.p60 .bar {
  transform: rotate(216deg);
}

.c100.p61 .bar {
  transform: rotate(219.6deg);
}

.c100.p62 .bar {
  transform: rotate(223.2deg);
}

.c100.p63 .bar {
  transform: rotate(226.8deg);
}

.c100.p64 .bar {
  transform: rotate(230.4deg);
}

.c100.p65 .bar {
  transform: rotate(234deg);
}

.c100.p66 .bar {
  transform: rotate(237.6deg);
}

.c100.p67 .bar {
  transform: rotate(241.2deg);
}

.c100.p68 .bar {
  transform: rotate(244.8deg);
}

.c100.p69 .bar {
  transform: rotate(248.4deg);
}

.c100.p70 .bar {
  transform: rotate(252deg);
}

.c100.p71 .bar {
  transform: rotate(255.6deg);
}

.c100.p72 .bar {
  transform: rotate(259.2deg);
}

.c100.p73 .bar {
  transform: rotate(262.8deg);
}

.c100.p74 .bar {
  transform: rotate(266.4deg);
}

.c100.p75 .bar {
  transform: rotate(270deg);
}

.c100.p76 .bar {
  transform: rotate(273.6deg);
}

.c100.p77 .bar {
  transform: rotate(277.2deg);
}

.c100.p78 .bar {
  transform: rotate(280.8deg);
}

.c100.p79 .bar {
  transform: rotate(284.4deg);
}

.c100.p80 .bar {
  transform: rotate(288deg);
}

.c100.p81 .bar {
  transform: rotate(291.6deg);
}

.c100.p82 .bar {
  transform: rotate(295.2deg);
}

.c100.p83 .bar {
  transform: rotate(298.8deg);
}

.c100.p84 .bar {
  transform: rotate(302.4deg);
}

.c100.p85 .bar {
  transform: rotate(306deg);
}

.c100.p86 .bar {
  transform: rotate(309.6deg);
}

.c100.p87 .bar {
  transform: rotate(313.2deg);
}

.c100.p88 .bar {
  transform: rotate(316.8deg);
}

.c100.p89 .bar {
  transform: rotate(320.4deg);
}

.c100.p90 .bar {
  transform: rotate(324deg);
}

.c100.p91 .bar {
  transform: rotate(327.6deg);
}

.c100.p92 .bar {
  transform: rotate(331.2deg);
}

.c100.p93 .bar {
  transform: rotate(334.8deg);
}

.c100.p94 .bar {
  transform: rotate(338.4deg);
}

.c100.p95 .bar {
  transform: rotate(342deg);
}

.c100.p96 .bar {
  transform: rotate(345.6deg);
}

.c100.p97 .bar {
  transform: rotate(349.2deg);
}

.c100.p98 .bar {
  transform: rotate(352.8deg);
}

.c100.p99 .bar {
  transform: rotate(356.4deg);
}

.c100.p100 .bar {
  transform: rotate(360deg);
}

.c100:hover {
  cursor: default;
}

.c100:hover > span {
  width: 3.33em;
  line-height: 3.33em;
  font-size: 0.3em;
  color: #307bbb;
}

.c100:hover:after {
  top: 0.04em;
  left: 0.04em;
  width: 0.92em;
  height: 0.92em;
}

.c100.dark {
  background-color: #777777;
}

.c100.dark .bar,
.c100.dark .fill {
  border-color: #c6ff00 !important;
}

.c100.dark > span {
  color: #777777;
}

.c100.dark:after {
  background-color: #666666;
}

.c100.dark:hover > span {
  color: #c6ff00;
}

.c100.green .bar,
.c100.green .fill {
  border-color: #4db53c !important;
}

.c100.green:hover > span {
  color: #4db53c;
}

.c100.green.dark .bar,
.c100.green.dark .fill {
  border-color: #5fd400 !important;
}

.c100.green.dark:hover > span {
  color: #5fd400;
}

.c100.orange .bar,
.c100.orange .fill {
  border-color: #dd9d22 !important;
}

.c100.orange:hover > span {
  color: #dd9d22;
}

.c100.orange.dark .bar,
.c100.orange.dark .fill {
  border-color: #e08833 !important;
}

.c100.orange.dark:hover > span {
  color: #e08833;
}
.title-text {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.65);
}
/*End Progress bar */

/* Outdoor table */
.tblOutdoor {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 17px;
}
.tblOutdoor td {
  border: 1px solid #e8e8e8;
  text-align: left;
  padding: 8px;
  border-left: none;
  font-weight: 500 !important;
  font-size: 14px;
  border-right: none;
}
.tblOutdoor th {
  font-weight: 500 !important;
  text-align: left;
  font-size: 14px;
  padding: 8px;
  color: rgba(0, 0, 0, 0.85);
  border-left: none;
  border-right: none;
  background: #fafafa;
  border-bottom: 1px solid #e8e8e8;
}
.section-wrapper {
  border-top: 3px solid cadetblue;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  background-color: rgb(255, 255, 255);
  border-radius: 3px;
  padding: 0px 8px 8px 8px;
  margin-top: 10px;
  margin-bottom: 25px;
}

/*END Outdoor table */

/* Raport button */
.btnRaport {
  background-color: #5f9ea0;
  border: none;
  color: white;
  padding: 7px 7px;
  cursor: pointer;
  border-radius: 2px;
  font-size: 13px;
  float: right;
  margin-top: 10px;
  margin-right: 10px;
}

/* End Raport button*/

/* loader spinner */
.loader {
  margin: 0 auto;
  width: 50px;
}
.loader:before {
  content: "";
  display: block;
  padding-top: 100%;
}
.circular {
  -webkit-animation: rotate 2s linear infinite;
          animation: rotate 2s linear infinite;
  height: 100%;
  transform-origin: center center;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
.path {
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  -webkit-animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
          animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
  stroke-linecap: round;
}
@-webkit-keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}
@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}
@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}
@-webkit-keyframes color {
  100%,
  0% {
    stroke: cadetblue;
  }
  40% {
    stroke: #0057e7;
  }
  66% {
    stroke: #008744;
  }
  80%,
  90% {
    stroke: rgb(0, 37, 58);
  }
}
@keyframes color {
  100%,
  0% {
    stroke: cadetblue;
  }
  40% {
    stroke: #0057e7;
  }
  66% {
    stroke: #008744;
  }
  80%,
  90% {
    stroke: rgb(0, 37, 58);
  }
}
.showbox {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 5%;
}

/*END loader spinner*/

/* END Main Content CSS */

/* INFOGRAPHICS CONTENT */
.infographics-content {
  box-shadow: rgba(0, 0, 0, 0.07) 0px 2px 8px;
  width: 80%;
  height: 100%;
  margin: 0px auto;
  padding: 32px;
  background: white;
}
/* selected tags */
.hAbWpv {
  background-color: white;
  display: flow-root;
  margin-bottom: 19px;
  padding: 15px;
}

/*end selected tags */
h3 {
  font-size: 1.3rem !important;
}
h1 {
  margin-top: 0 !important;
  margin-bottom: 0.5em !important;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 400 !important;
  font-size: 2em !important;
}
.ant-btn-primary-infographics {
  color: #fff;
  text-transform: uppercase;
  background-color: #5f9ea0;
  border-color: #5f9ea0;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  font-weight: 400;
  min-width: 48px;
  margin-bottom: 30px;
}
.ant-btn-primary-infographics:hover {
  color: #fff;
  background-color: #3f7f81;
  border: 1px solid rgb(0, 37, 58) !important;
}

.side-navbar-links {
  color: rgba(255, 255, 255, 0.65) !important;
  text-decoration: none;
  background-color: transparent;
}

.side-navbar-links:hover {
  color: white !important;
  text-decoration: none;
  background-color: transparent;
}

.info-header {
  box-shadow: rgba(0, 0, 0, 0.04) 0px 1px 2px;
  padding: 19px 40px 16px;
  background: rgb(255, 255, 255);
}
.info-header-name-button {
  display: flex;
  justify-content: space-between;
}
.info-header h2 {
  color: rgb(0, 0, 0);
  font-size: 24px;
  margin: 0px;
  margin-top: 5px;
}
.info-content-wrapper {
  display: flex;
  flex: 1 1;
  overflow: auto;
}
.info-content-left {
  min-width: 0px;
  flex: 1 1;
  overflow: auto;
}
.info-content-right {
  position: relative;
  -webkit-user-select: auto;
     -moz-user-select: auto;
      -ms-user-select: auto;
          user-select: auto;
  transition: all 0.35s ease-in-out 0s;
  z-index: 210;
  width: 480px;
  max-width: 480px;
  min-width: 300px;
  box-sizing: border-box;
  flex-shrink: 0;
}
.content-left-header {
  padding: 16px 40px;
}
.content-left-header-wrapper {
  display: flex;
  justify-content: space-between;
}
.content-left-header .search-controls {
  flex: 1 1;
}
.content-left-wrapper {
  padding: 16px 40px;
}
.prev-image-wrapper {
  display: inline-block;
  font-size: 14px;
  width: 100%;
  padding: 16px;
}
.prev-info-name {
  display: flex;
  align-items: center;
  margin-bottom: 2px;
  font-size: 14px;
  font-weight: 400;
}
.infographic-name {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.info-button {
  display: flex;
  justify-content: space-between;
  padding-top: 16px;
  padding-bottom: 16px;
}
.details {
  display: flex;
  justify-content: space-between;
  margin-top: 0;
  font-size: 1rem !important;
  margin-bottom: 0.5em;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 400;
  margin-left: 13px;
  margin-top: 13px;
}

/* END INFOGRAPHICS CONTENT */

/* Nav bar backgroundcolor */
.ant-menu-dark {
  color: rgba(255, 255, 255, 0.65);
  background: #5f9ea0 !important;
}
.divider {
  position: relative;
  -webkit-user-select: auto;
     -moz-user-select: auto;
      -ms-user-select: auto;
          user-select: auto;
  transition: all 0.35s ease-in-out 0s;
  z-index: 210;
  width: 480px;
  max-width: 480px;
  min-width: 300px;
  box-sizing: border-box;
  flex-shrink: 0;
}
.info-right-wrapper {
  display: flex;
  flex-direction: column;
  border-left: 1px solid rgb(217, 217, 217);
  overflow: auto;
}

/* Login */

.has-error.has-feedback .ant-form-item-children-icon {
  display: none !important;
  color: #f5222d;
  -webkit-animation-name: diffZoomIn2 !important;
  animation-name: diffZoomIn2 !important;
}
/*End Login*/

/* Infographics */
.block {
  margin-left: 15px;
}

.icon-inline {
  display: inline-block;
}
.btn-groups {
  margin-right: 10px;
}
.btn-name {
  color: white;
  font-size: 12px;
  float: right;
  display: grid;
  margin-right: 15px;
}
.new-header-nav {
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: rgb(67, 75, 87);
  height: 50px;
}

.new-content {
  display: flex;
  flex-direction: row;
  position: relative;
}
.nav-content {
  top: 0px;
  right: 0px;
  bottom: 0px;
  width: 500px;
  position: absolute;
  z-index: 250;
  min-height: calc(100vh - 53px);
  background: rgb(0, 37, 58);
  overflow: auto;
}

.nav-conetent-margins {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 10px;
}
.side-color {
  top: 0px;
  right: 0px;
  bottom: 0px;
  width: 300px;
  position: absolute;
  z-index: 250;
  min-height: calc(100vh - 53px);
  background: rgb(0, 37, 58);
  overflow: auto;
}
.side-color-margins {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 20px;
}
.site-tag-plus {
  background: #fff;
  border-style: dashed;
}
.site-back-top-basic {
  color: rgba(64, 64, 64, 0.6);
}
#components-back-top-demo-custom .ant-back-top {
  bottom: 100px;
}
#components-back-top-demo-custom .ant-back-top-inner {
  height: 40px;
  width: 40px;
  line-height: 40px;
  border-radius: 4px;
  background-color: #1088e9;
  color: #fff;
  text-align: center;
  font-size: 20px;
}
.infographic-view-container {
  display: flex;
  flex-direction: column;
}

.input-name {
  font-size: 1rem;
  color: white;
  width: 50%;
  padding: 5px;
}

.nav-wrapper {
  float: right;
  margin-right: 15px;
  display: flex;
  align-items: center;
}

.expand-tags {
  display: flex;
  flex-direction: column;
  margin-left: 5px;
  margin-right: 5px;
  white-space: nowrap;
  color: rgba(255, 255, 255, 0.8);
  padding: 8px;
  background: inherit;
}

.expand-tags-active {
  display: flex;
  flex-direction: column;
  margin-left: 5px;
  margin-right: 5px;
  font-size: 0.8rem;
  white-space: nowrap;
  color: rgba(255, 255, 255, 0.8);
  padding: 9px;
  background: rgb(0, 37, 58);
}
.nav-content-margins {
  padding: 15px;
}
.expand-tags:hover {
  background: #505965;
  height: 50px;
}

/* End infographics */
/* Timeseries */

.timeseries-header {
  display: flex;
  height: 64px;
  padding-left: 18px;
  justify-content: space-between;
  align-items: center;
  background: white;
}

.timeseries-header .asset-name {
  padding-left: 10px;
  float: right;
}
.sensor-name {
  font-size: 16px;
  font-weight: bold;
}
.sensor-name .editable-text-disabled {
  height: 24px;
}
.text-font {
  font-weight: 500;
}
.timeseries-chart {
  padding: 18px 18px 0px 18px;
}
.display-grid-name {
  display: inline-block;
  font-size: 14px;
  width: 100%;
  padding: 14px;
  border-bottom: 1px solid rgb(217, 217, 217);
}
.display-grid-name .title {
  text-transform: uppercase;
  color: #595959;
  font-size: 12px;
  padding-bottom: 8px;
}
.info-right-wrapper.display-grid-name {
  border-bottom: 1px solid black;
}
.grid-wrapper {
  display: inline-block;
  font-size: 14px;
  width: 100%;
  /* padding: 16px; */
}
.display-grid-name .latest-reading {
  font-size: 32px;
  margin-bottom: 0px;
  display: flex;
  align-items: center;
}
.display-grid-name .latest-reading p {
  margin-bottom: 5px;
  font-size: 38px;
}

.sensor-value {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.display-grid-name .latest-reading.unit {
  font-size: 24px;
}
.unit {
  font-size: 24px;
  margin-bottom: 10px;
}
/*End Timeseries*/

/* Dashboard */

.dashboard-header {
  font-feature-settings: "tnum", "tnum";
  color: white;
  font-size: 18px !important;
  color: white !important;
}
.visualization-wrapper {
  display: flex;
  flex-direction: column;
  margin: 16px 0px 32px;
  padding: 8px;
}
.visualization-buttons {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 8px;
  gap: 8px;
}
.button-content {
  width: 250px;
  border: 1px solid #d9d9d9;
  cursor: pointer !important;
  text-align: start !important;
  display: flex !important;
  align-items: center !important;
  background: inherit !important;
  padding: 16px !important;
}
.button-content:hover {
  border: 1px solid #72b9bb;
}
.button-content h4 {
  font-size: 14px;
  text-transform: uppercase !important;
  margin-bottom: 0px !important;
}
.button-content p {
  font-size: 12px !important;
  margin-top: 6px;
}

.board {
  box-shadow: rgba(0, 0, 0, 0.2) 1px 1px 3px;
  position: relative;
  min-height: 10px;
  width: calc(100% - 32px);
  margin: 16px;
  padding: 24px;
  background: white;
}
.board-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}

.date-picker {
  padding: 24px;
}
.date-picker-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
}

.date-picker-range {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
}
.date {
  color: rgba(0, 0, 0, 0.65);
  opacity: 0.6;
  font-size: 14px;
  cursor: pointer;
  padding: 6px 4px;
}
.date:hover {
  opacity: 1;
}

.date:focus {
  opacity: 1;
  box-shadow: rgba(74, 103, 251, 0.2) 0px 2px 10px 0px !important;
  outline: none;
  background: cadetblue;
  color: white;
}
.editable-name {
  font-size: 16px;
  font-weight: bold;
}
.editable-name-default {
  display: flex;
  font-weight: 700;
  cursor: pointer;
  color: #595959;
  padding: 5px 8px;
}
.editable-name-default:hover {
  background-color: #f5f5f5;
  cursor: pointer;
}
.input-text {
  border: none;
  width: 100%;
  font-weight: 700;
  font-size: inherit;
  border-width: medium;
  border-width: initial;
  border-style: none;
  border-color: currentColor;
  border-color: initial;
  border-image: none;
  border-image: initial;
  padding: 2px 8px;
  transition: none 0s ease 0s;
}
.filter-board {
  margin-left: 24px;
  background: #00253a;
  color: white;
  border-color: #00253a;
}
.filter-board:hover {
  background: #00253a;
  color: white;
  border-color: #00253a;
}
/* End dashboard */

/* Spin */
.spin {
  text-align: center;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  margin-bottom: 20px;
  padding: 30px 50px;
  margin: 20px 0;
}

/* End Spin */
.ant-tree li .ant-tree-node-content-wrapper {
  display: inline-block;
  height: 24px;
  margin: 0;
  padding: 0 5px;
  color: #b8c7ce;
  line-height: 24px;
  text-decoration: none;
  vertical-align: top;
  border-radius: 2px;
  cursor: pointer;
  transition: all 0.3s;
}
.ant-tree li {
  margin: 0;
  padding: 4px 0;
  white-space: nowrap;
  list-style: none;
  outline: 0;
  color: #b8c7ce;
}

/* Model3DViewer */

.model3D {
  position: fixed;
  height: 100%;
  width: 100%;
}

/* End Model3DViewer */

.ejbipv {
  min-width: 215px;
  margin-bottom: 25px;
  display: inline-block;
  /*cursor: pointer; */
}
.edit_tag {
  flex-direction: row;
  justify-content: space-between;
  padding: 15px;
}
.tag_id {
  color: white;
  font-size: 12px;
  opacity: 0.4;
}
.tag_color {
  text-transform: uppercase;
  color: white;
  font-size: 1.5em;
}
.tag_Yaxis h2 {
  color: white;
  margin-top: 5px;
  text-transform: uppercase;
  font-size: 1.5em;
}
.tag-color {
  width: 8px;
  height: 20px;
  display: inline-block;
  border-radius: 10px;
  margin: auto 16px;
}
/* selected timeseries are displyed as none*/
.hAbWpv {
  background-color: white;
  display: flow-root;
  margin-bottom: 19px;
  padding: 15px;
  display: none;
}
/* END selected timeseries are displyed as none*/

